import React, { useEffect, useState } from "react";
import { Alert, CircularProgress, Container } from "@mui/material";
import { useSearchParam } from "react-use";
import { app } from "../utils/server/firebase";
import moment from "moment";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const db = getFirestore(app);

const ValidateQr = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [info, setInfo] = useState();
  const email = useSearchParam("m");
  const evento = useSearchParam("ev");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setError("");
        const registroCollection = collection(db, "reservacionCatas");
        const q = query(registroCollection, where("email", "==", email));

        const snapshot = await getDocs(q);

        if (snapshot.empty) {
          return setError("El correo no se encuentra registrado");
        }

        snapshot.forEach((doc) => {
          const data = doc.data();
          data.id = doc.id;
          setInfo(data);

          if (data.used === true) {
            return setError("El Qr ya fue utilizado");
          } else {
            /* if (!moment().isSame(moment(data.date, "YYYY-MM-DD"), "dates")) {
            return setError(
              "Este Qr solo puede ser utilizado el día de la cata."
            );
          }
          if (
            !moment(new Date(), "HH:mm:ss").isBetween(
              moment(data.schedule.startHour, "HH:mm:ss").add(-15, "minutes"),
              moment(data.schedule.startHour, "HH:mm:ss").add(15, "minutes")
            )
          ) {
            return setError(
              "Este Qr solo puede ser utilizado a la hora de inicio de la cata, con 15 minutos de tolerancia."
            );
          }  */
            const updateRegistro = async (data) => {
              try {
                const docRef = doc(db, "registro", data.id);
                await updateDoc(docRef, {
                  used: true,
                });
              } catch (error) {
                console.log(error);
              }
            };
            updateRegistro(data.id);
          }
        });
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [email, evento]);

  if (loading) return <CircularProgress size={50} color="primary" />;

  return (
    <Container component="main" maxWidth="sm">
      {!error && info ? (
        <Alert severity="success">
          Acceso autorizado - {info.evento} (
          {`
                
                  ${moment(info.cataDate).format("DD [de] MMMM")}  
                 ${info.cataHour} 
                  `}
          )
        </Alert>
      ) : (
        <Alert severity="error">{error}</Alert>
      )}
    </Container>
  );
};

export default ValidateQr;
